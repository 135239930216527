import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "mb-3 flex justify-end items-center space-x-3" }
const _hoisted_5 = { class: "col-span-12" }
const _hoisted_6 = { class: "box py-5" }
const _hoisted_7 = { class: "flex items-center space-x-3" }
const _hoisted_8 = { class: "flex items-center space-x-2" }
const _hoisted_9 = ["onClick"]

import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { ITenantDTO, ITenantUserParameters } from "@/models/TenantUser";
import DialogVerify from "@/components/TheDialog/DialogVerify.vue";
import DialogModule from "@/store/UI/Dialog";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { USER } from "@ems/constants";
import { formatLocalDateTime } from "@/utils/helpers/convertDateTime";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const pagination = {
  CurrentPage: 1,
  Limit: 10,
  TotalRow: 20,
};
const tenantUserData = computed(
  () => TenantUserModule.dataListTenantUserGetter
);

const isLoadingTenantUserData = computed(
  () => TenantUserModule.isLoadingListTenantUserGetter
);

const handleSizeChange = () => {
  //Todo implement feature
};

const handleCurrentChange = () => {
  //Todo implement feature
};

onMounted(() => {
  const data = {} as ITenantUserParameters;
  TenantUserModule.getListTenantUser({ data });
});

const handleDetailUser = (_: number, row: ITenantDTO) => {
  router.push(`${route.path}/${row.Id}/edit`);
};
const handleActiveUser = (_index: number, row: any) => {
  DialogModule.showModal({
    component: DialogVerify,
    data: {
      title: "Activate Account",
      description: `Do you want to activate account ${row.UserName}?`,
      data: row,
    },
  });
  return;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Account Management")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn--green-primary",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).push(`${_unref(route).path}/${_unref(USER).USER_CREATE.path}`)), ["prevent"]))
      }, " Add New User ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          class: "w-full table-custom",
          style: { borderRadius: '4px' },
          data: tenantUserData.value,
          stripe: "",
          height: "66vh",
          "cell-class-name": "text-cafe-dark",
          "header-row-class-name": "tableHeaderRow",
          "row-class-name": "tableRow",
          "element-loading-text": "Loading...",
          "element-loading-background": "rgba(0, 0, 0, 0.6)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              type: "index",
              label: "No."
            }),
            _createVNode(_component_el_table_column, {
              prop: "Firstname",
              label: "First Name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Lastname",
              label: "Last Name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "PhoneNumber",
              label: "Phone Number"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Email",
              label: "Email"
            }),
            _createVNode(_component_el_table_column, { label: "Last Login" }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(scope.row.LastLogin
                      ? _unref(formatLocalDateTime)(scope.row.LastLogin)
                      : "NA"), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "Action",
              width: "80"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _withModifiers(($event: any) => (handleDetailUser(scope.$index, scope.row)), ["prevent"])
                  }, [
                    _createVNode(BaseSvgIcon, {
                      class: "inline-block text-cafe-dark hover:text-blue-400 w-5 h-5",
                      name: "edit"
                    })
                  ], 8, _hoisted_9)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])), [
          [_directive_loading, isLoadingTenantUserData.value]
        ])
      ])
    ])
  ]))
}
}

})